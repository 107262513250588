import { Injectable } from "@angular/core";

export enum MRZType {
    TD1,
    TD3,
    SAUDI_ID,
}

@Injectable({
    providedIn: "root",
})
export class MRZDecoderService {
    private readonly DOCUMENT_CODE_REGEX = /^[A-Z]{1,2}$/;
    private readonly COUNTRY_CODE_REGEX = /^[A-Z]{3}$/;

    constructor() {}

    decode(mrz: string[]): Record<string, any> {
        if (mrz.length !== 2 && mrz.length !== 3) {
            throw new Error("Invalid MRZ: Must have 2 or 3 lines");
        }

        const mrzType = this.detectMRZType(mrz);
        const result: Record<string, any> = { type: MRZType[mrzType] };

        switch (mrzType) {
            case MRZType.TD1:
                this.decodeTD1(mrz, result);
                break;
            case MRZType.TD3:
                this.decodeTD3(mrz, result);
                break;
            case MRZType.SAUDI_ID:
                this.decodeSaudiID(mrz, result);
                break;
        }

        return result;
    }

    private detectMRZType(mrz: string[]): MRZType {
        if (mrz.length === 2) {
            return MRZType.TD3;
        } else if (mrz[0].startsWith("IDSAU")) {
            return MRZType.SAUDI_ID;
        } else {
            return MRZType.TD1;
        }
    }

    private decodeTD1(mrz: string[], result: Record<string, any>): void {
        const [line1, line2, line3] = mrz;

        result.documentCode = this.extractDocumentCode(line1.slice(0, 2));
        result.issuingCountry = this.extractCountryCode(line1.slice(2, 5));
        result.documentNumber = line1.slice(5, 14).replace(/</g, "");
        result.optionalData1 = line1.slice(15, 30).trim().replace(/</g, "");

        result.birthDate = this.extractDate(line2.slice(0, 6));
        result.sex = this.extractSex(line2.charAt(7));
        result.expiryDate = this.extractDate(line2.slice(8, 14));
        result.nationality = this.extractCountryCode(line2.slice(15, 18));
        result.optionalData2 = line2.slice(18, 29).trim().replace(/</g, "");

        const names = line3.split("<<");
        result.surname = names[0].replace(/</g, " ").trim();
        result.givenNames = names[1].replace(/</g, " ").trim();
    }

    private decodeTD3(mrz: string[], result: Record<string, any>): void {
        const [line1, line2] = mrz;

        result.documentCode = this.extractDocumentCode(line1.slice(0, 2));
        result.issuingCountry = this.extractCountryCode(line1.slice(2, 5));

        const names = line1.slice(5).split("<<");
        result.surname = names[0].replace(/</g, " ").trim();
        result.givenNames = names[1].replace(/</g, " ").trim();

        result.documentNumber = line2.slice(0, 9).replace(/</g, "");
        result.nationality = this.extractCountryCode(line2.slice(10, 13));
        result.birthDate = this.extractDate(line2.slice(13, 19));
        result.sex = this.extractSex(line2.charAt(20));
        result.expiryDate = this.extractDate(line2.slice(21, 27));
        result.optionalData = line2.slice(28, 42).trim().replace(/</g, "");
    }

    private decodeSaudiID(mrz: string[], result: Record<string, any>): void {
        const [line1, line2, line3] = mrz;

        result.documentCode = this.extractDocumentCode(line1.slice(0, 2));
        result.issuingCountry = this.extractCountryCode(line1.slice(2, 5));
        result.documentNumber = line1.slice(5, 14);
        result.checkDigitDocumentNumber = line1.charAt(14);
        result.optionalData = line1.slice(15, 30).trim().replace(/</g, "");

        result.birthDate = this.extractDate(line2.slice(0, 6));
        result.checkDigitBirthDate = line2.charAt(6);
        result.sex = this.extractSex(line2.charAt(7));
        result.expiryDate = this.extractDate(line2.slice(8, 14));
        result.checkDigitExpiryDate = line2.charAt(14);
        result.nationality = this.extractCountryCode(line2.slice(15, 18));
        result.optionalData2 = line2.slice(18, 29).trim().replace(/</g, "");
        result.overallCheckDigit = line2.charAt(29);

        const names = line3.split("<<");
        result.surname = names[0].replace(/</g, " ").trim();
        result.givenNames = names.slice(1).join(" ").replace(/</g, " ").trim();
    }

    private extractDocumentCode(code: string): string {
        if (this.DOCUMENT_CODE_REGEX.test(code)) {
            return code;
        }
        throw new Error("Invalid document code");
    }

    private extractCountryCode(code: string): string {
        if (this.COUNTRY_CODE_REGEX.test(code)) {
            return code;
        }
        throw new Error("Invalid country code");
    }

    private extractDate(date: string): string {
        const year = date.slice(0, 2);
        const month = date.slice(2, 4);
        const day = date.slice(4, 6);
        const fullYear = parseInt(year) > 50 ? `19${year}` : `20${year}`; // Assuming 50 as a cutoff for 1900s/2000s
        return `${day}/${month}/${fullYear}`;
    }

    private extractSex(sex: string): string {
        if (sex === "M" || sex === "F") {
            return sex;
        }
        return "X"; // Unspecified
    }
}
