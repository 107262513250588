import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Data, Image, ImageTypeEnum } from "../../../dto";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import { catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/service/data.service";
import { trackJourney } from "src/app/utils/utils";
import { RefService } from "src/app/service/ref.service";

@Component({
    selector: "app-submit",
    templateUrl: "./submit.component.html",
    styleUrls: ["./submit.component.scss"],
})
export class SubmitComponent {
    data: Data;

    constructor(
        private router: Router,
        private httpService: HttpFacetecService,
        private refService: RefService,
        private dataService: DataService,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private zone: NgZone,
    ) {
        trackJourney({
            ref: this.refService.getRef(),
            page: "submit_start",
            httpService: this.httpService,
        });
        this.data = this.dataService.getData();
    }

    getImage(ordinal: number) {
        switch (ordinal) {
            case ImageTypeEnum.Portrait:
                return (
                    "data:image/jpeg;base64," +
                    this.data.imageList.filter((item) => item.type == ImageTypeEnum.Portrait)[0]
                        .image
                );
            case ImageTypeEnum.IdPhotoFace:
                return (
                    "data:image/jpeg;base64," +
                    this.data.imageList.filter((item) => item.type == ImageTypeEnum.IdPhotoFace)[0]
                        .image
                );
            case ImageTypeEnum.IdPhotoFront:
                return (
                    "data:image/jpeg;base64," +
                    this.data.imageList.filter((item) => item.type == ImageTypeEnum.IdPhotoFront)[0]
                        .image
                );
            case ImageTypeEnum.IdPhotoBack:
                return (
                    "data:image/jpeg;base64," +
                    this.data.imageList.filter((item) => item.type == ImageTypeEnum.IdPhotoBack)[0]
                        .image
                );
            case ImageTypeEnum.IdSignature:
                return (
                    "data:image/jpeg;base64," +
                    this.data.imageList.filter((item) => item.type == ImageTypeEnum.IdSignature)[0]
                        .image
                );
            case ImageTypeEnum.Signature:
                return this.data.imageList.filter((item) => item.type == ImageTypeEnum.Signature)[0]
                    .image;
            default:
                return null;
        }
    }

    getImageType(ordinal: number) {
        switch (ordinal) {
            case ImageTypeEnum.Portrait:
                return "Portrait";
            case ImageTypeEnum.IdPhotoFace:
                return "ID Face";
            case ImageTypeEnum.IdPhotoFront:
                return "ID Front";
            case ImageTypeEnum.IdPhotoBack:
                return "ID Back";
            case ImageTypeEnum.IdSignature:
                return "ID Signature";
            case ImageTypeEnum.Signature:
                return "Signature";
            default:
                return "Unknown";
        }
    }

    submit() {
        var images = this.data.imageList.map((item) => {
            var imageData = item.image;
            if (item.type === ImageTypeEnum.Signature) {
                //Remove the base64 prefix crap
                imageData = (imageData as string).replace("data:image/png;base64,", "");
            }

            return {
                image: imageData,
                type: item.type,
            };
        });

        var data: Data = {
            sessionId: this.data.sessionId,
            firstName: this.data.firstName,
            lastName: this.data.lastName,
            fullName: this.data.fullName,
            nationality: this.data.nationality,
            nationalityNonMRZValue: this.data.nationalityNonMRZValue,
            countryCode: this.data.countryCode,
            countryCodeNonMRZValue: this.data.countryCodeNonMRZValue,
            dateOfBirth: this.data.dateOfBirth,
            placeOfBirth: this.data.placeOfBirth,
            idNumber: this.data.idNumber,
            idGroupName: this.data.idGroupName,
            idBarcode: this.data.idBarcode,
            dateOfIssue: this.data.dateOfIssue,
            dateOfExpiration: this.data.dateOfExpiration,
            sex: this.data.sex, //yes pleassexe
            address: this.data.address,
            imageList: images,
            userConfirmedValues: this.data.userConfirmedValues,
            barcodeData: this.data.barcodeData, //will be a json string of the ocrData as received from FaceTec - we should remove this after testing is completbarcodeDatae
            barcodeTemplateName: this.data.barcodeTemplateName,
            barcodeTemplateType: this.data.barcodeTemplateType,
            ocrData: this.data.ocrData, //will be a json string of the ocrData as received from FaceTec - we should remove this after testing is completocrDatae
            ocrTemplateName: this.data.ocrTemplateName,
            ocrTemplateType: this.data.ocrTemplateType,
            idPhotoMatchLevel: this.data.idPhotoMatchLevel,
            ageEstimate: this.data.ageEstimate,
            browser: this.data.browser,
            deviceModel: this.data.deviceModel,
            deviceSDK: this.data.deviceSDK,
            ipAddress: this.data.ipAddress,
            platform: this.data.platform,
        };

        this.spinner.show();
        this.httpService
            .process(this.data.ref, data)
            .pipe(
                catchError((error) => {
                    this.spinner.hide();
                    console.log("Error submitting data", error);
                    this.toastr.error(error.statusText ? error.statusText : error.message, "Error");
                    throw error;
                }),
            )
            .subscribe((result) => {
                this.spinner.hide();
                if (!result) {
                    this.toastr.error("Failed to submit data", "Error");
                    return;
                }
                if (result.status) {
					trackJourney({
						ref: this.refService.getRef(),
						page: "submit_success",
						httpService: this.httpService,
					});
                    this.toastr.success("Submitted successfully", "Success");

                    if (result.redirectUrl) {
                        this.zone.runOutsideAngular(() => {
                            setTimeout(function () {
                                document.location.href = result.redirectUrl;
                            }, 1000);
                        });
                    }

                    return;
                }

                this.toastr.error("Submission failed: " + result.error, "Error");
            });
    }
}
