<div class="light-grey">
	<div class="row">
		<div class="col text-center title">Authentication</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-0 bg-white shadow-sm border-white rounded">
				<div class="card-body p-0 pb-2">
					<div class="block-background">
						<div class="block-info">
							<div class="row">
								<div class="col">
									<div class="card m-3 bg-white rounded">
										<div class="card-body">
											<div class="row">
												<div class="col-2"><img src="assets/images/portrait.png" height="30px"></div>
												<div class="col">
													<div class="row">
														<div class="col text-start title">Step 1: Take Your Photo</div>
													</div>
												</div>
												<div class="col-2"><img src="assets/images/done.png" height="20px"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<div class="card m-3 bg-white rounded">
										<div class="card-body">
											<div class="row">
												<div class="col-2"><img src="assets/images/document.png" height="30px"></div>
												<div class="col">
													<div class="row">
														<div class="col text-start title">Step 2: Scan your document</div>
													</div>
												</div>
												<div class="col-2"><img src="assets/images/done.png" height="20px"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<div class="card m-3 bg-white rounded">
										<div class="card-body">
											<div class="row">
												<div class="col-2"><img src="assets/images/review.png" height="30px"></div>
												<div class="col">
													<div class="row">
														<div class="col text-start title">Step 3: Review Information</div>
													</div>
												</div>
												<div class="col-2"><img src="assets/images/done.png" height="20px"></div>
											</div>
										</div>
									</div>
								</div>
							</div>							
							<div class="row">
								<div class="col">
									<div class="card m-3 bg-white rounded">
										<div class="card-body">
											<div class="row">
												<div class="col-2"><img src="assets/images/signature_red.png" height="30px"></div>
												<div class="col">
													<div class="row">
														<div class="col text-start title">Step 4: Add Signature</div>
													</div>
												</div>
												<div class="col-2"><img src="assets/images/done.png" height="20px"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- <div class="row">
								<div class="col">Review</div>
							</div>
							<div class="row">
								<div class="col">
									<div class="card m-3 bg-white rounded">
										<div class="card-body table-responsive">
											<table class="table table-borderless">
												<tbody>
													<tr *ngIf="data.fullName">
														<th>Full name</th>
														<td>{{data.fullName}}</td>
													</tr>
													<tr *ngIf="!data.fullName">
														<th>First name</th>
														<td>{{data.firstName}}</td>
													</tr>
													<tr *ngIf="!data.fullName">
														<th>Last name</th>
														<td>{{data.lastName}}</td>
													</tr>
													<tr>
														<th>Nationality</th>
														<td>{{data.nationality}}</td>
													</tr>
													<tr>
														<th>Date of Birth</th>
														<td>{{data.dateOfBirth}}</td>
													</tr>
													<tr>
														<th>Place of Birth</th>
														<td>{{data.placeOfBirth}}</td>
													</tr>
													<tr>
														<th>ID Number</th>
														<td>{{data.idNumber}}</td>
													</tr>
													<tr>
														<th>Date of Issue</th>
														<td>{{data.dateOfIssue}}</td>
													</tr>
													<tr>
														<th>Gender</th>
														<td>{{data.sex}}</td>
													</tr>
													<tr>
														<th>Address</th>
														<td>{{data.address}}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<div class="row" *ngFor="let image of data.imageList">
								<div class="col">
									<div class="card m-3 bg-white rounded">
										<div class="card-body">
											<div class="row">
												<div class="col">{{getImageType(image.type)}}</div>
											</div>
											<div class="row">
												<div class="col">
													<img height="100px" [src]="getImage(image.type)">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> -->
							<div class="row mt-2">
								<div class="d-grid gap-2 col-6 mx-auto space-after grid-button">
									<button (click)="submit()" class="btn bg-gradient btn-zain btn-zain-next">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
