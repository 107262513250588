<div class="light-grey" #top>
	<div class="row">
		<div class="col text-center title">Error</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-0 bg-white shadow-sm border-white rounded">
				<div class="card-body p-0 pb-2">
					<div class="block-background">
						<div class="block-info">
							<div id="status">
							</div>
							<div class="row" *ngIf="errorCount > 0">
								<div class="col">
									<div class="card m-3 bg-white rounded">
										<div class="card-body">
											<div class="row">
												<div class="col">
													<div class="row">
														<div class="col text-start title">Capture Failed</div>
													</div>
													<div class="row">
														<div class="col text-start">Error: {{lastErrorDescription}}
														</div>
													</div>
													<div class="row">
														<div class="col text-start">Would you like to skip the capture
															process?</div>
													</div>
													<div class="row">
														<div class="d-grid gap-2 col-6 mx-auto space-after grid-button">
															<button (click)="skip()"
																class="btn bg-gradient btn-zain btn-zain-next">Skip</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>