<div class="light-grey" #top>
	<div class="row">
		<div class="col text-center title">Authentication</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-0 bg-white shadow-sm border-white rounded">
				<div class="card-body p-0 pb-2">
					<div class="block-background">
						<div class="block-info">
								<div id="status">
								</div>
								<div *ngIf="ready">
									<div class="row">
										<div class="col">
											<div class="card m-3 bg-white rounded">
												<div class="card-body">
													<div class="row">
														<div class="col-2"><img src="assets/images/portrait.png" height="30px"></div>
														<div class="col">
															<div class="row">
																<div class="col text-start title">Step 1: Take Your Photo</div>
															</div>
															<div class="row mt-2">
																<div class="col text-start"><small>Please ensure you have adequate lighting</small></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col">
											<div class="card m-3 bg-white rounded">
												<div class="card-body">
													<div class="row">
														<div class="col-2"><img src="assets/images/document.png"  height="30px"></div>
														<div class="col">
															<div class="row">
																<div class="col text-start title">Step 2: Scan Your Document</div>
															</div>
															<div class="row mt-2">
																<div class="col text-start"><small>Please ensure you have one of the following allowed ID types:</small></div>
															</div>
														</div>
													</div>
													<div class="row">
														<div class="col" class="allowed-id-list text-start"><small [innerHTML]="util.getAllowedIdTypes(config)"></small></div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col">
											<div class="card m-3 bg-white rounded">
												<div class="card-body">
													<div class="row">
														<div class="col-2"><img src="assets/images/review.png"  height="30px"></div>
														<div class="col">
															<div class="row">
																<div class="col text-start title">Step 3: Review Information</div>
															</div>
															<div class="row mt-2">
																<div class="col text-start"><small>Review the information captured from your ID</small></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>									
									<div class="row">										
										<div class="col">
											<div class="card m-3 bg-white rounded">
												<div class="card-body">
													<div class="row">
														<div class="col-2"><img src="assets/images/signature_red.png"  height="30px"></div>
														<div class="col">
															<div class="row">
																<div class="col text-start title">Step 4: Add Your Signature</div>
															</div>
															<div class="row mt-2">
																<div class="col text-start"><small>Provide your signature</small></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row mt-2">
										<div class="d-grid gap-2 col-6 mx-auto space-after grid-button">
											<button (click)="start()" class="btn bg-gradient btn-zain btn-zain-next">Start</button>
										</div>
										<!-- <div class="d-grid gap-2 col-6 mx-auto">
											<button (click)="start()" class="btn btn-primary">Start</button>
										</div> -->
									</div>
									
								</div>
							<div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
