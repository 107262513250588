export class Image {
	image: any;
	type: ImageTypeEnum;
}

export enum ImageTypeEnum {
	Portrait,
	IdPhotoFront,
	IdPhotoBack,
	IdPhotoFace,
	IdSignature,
	Signature,
	TamperingFrontEvidence,
	TamperingBackEvidence,
}

export class Data {
	ref?: string;
	sessionId: string;
	firstName: string;
	lastName: string;
	fullName: string;
	nationality: string;
	nationalityNonMRZValue: string;
	countryCode: string;
	countryCodeNonMRZValue: string;
	dateOfBirth: string;
	placeOfBirth: string;
	idNumber: string;
	idBarcode: string;
	idGroupName: string;
	dateOfIssue: string;
	dateOfExpiration: string;
	sex: string; //yes please
	address: string;
	imageList: Image[];
	userConfirmedValues: any;
	barcodeData: any; //will be a json string of the ocrData as received from FaceTec - we should remove this after testing is complete
	barcodeTemplateName: string;
	barcodeTemplateType: string;
	ocrData: any; //will be a json string of the ocrData as received from FaceTec - we should remove this after testing is complete
	ocrTemplateName: string;
	ocrTemplateType: string;
	idPhotoMatchLevel: number;
	ageEstimate: number;
	browser: string; //userAgent
	deviceModel: string; //deviceModel
	deviceSDK: string; //deviceSDK
	platform: string; //platform
	ipAddress: string;
}

export class Ref {
	ref: string;
}

export class Response {
	status: boolean;
	error: string;
	redirectUrl: string; //upon completion, redirect user to this url
	config: Config;
}

export class Config {
	allowedIdTypes: AllowedIdType[];
	deviceKeyIdentifier: string;
	publicFaceScanEncryptionKey: string;
	productionKeyText: string;
	browserSdkLicense: string;
	url: string;
	transactionId: string;
	token: string;
	minMatchLevel: string;
}

export class AllowedIdType {
	groupName: string;
	templateTypes: string[];
	templateNames: string[];
	additionalDocs: AdditionalDoc[];
}

export class AdditionalDoc {
	docTypeId: number;
	name: string;
}

export class Error {
	errorType: ErrorType;
	data: Data;
	submitted?: boolean;
}

export enum ErrorType {
	FaceLivenessFailed, //Not a live face
	OcrTemplateMatchFailed, // ID does not have a corresponding template 
	FaceDidNotMatchId, //Portrait and face on ID do not match
	IdTypeNotAllowed, //Id Type blocked based on Axon's server config's allowed ID Types
	IdTypeUnexpectedMedia, //Not a "live" ID Card i.e possibly a picture of a picture (moire lines)
	IdScanFailed,
	AxonMissingData,
}
