import { Component, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Config, Data } from "../../../dto";
import { RefService } from "src/app/service/ref.service";
import { ConfigService } from "src/app/service/config.service";
import { DataService } from "src/app/service/data.service";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import { trackJourney } from "src/app/utils/utils";

@Component({
    selector: "app-review",
    templateUrl: "./review.component.html",
    styleUrls: ["./review.component.scss"],
})
export class ReviewComponent {
    ref: string;
    config: Config;
    data: Data;

    reviewData = [];

    constructor(
        private router: Router,
        private refService: RefService,
        private configService: ConfigService,
        private dataService: DataService,
        private httpService: HttpFacetecService,
        private zone: NgZone,
    ) {
        this.ref = this.refService.getRef();
        this.config = this.configService.getConfig() as Config;
        this.data = this.dataService.getData();

        trackJourney({ ref: this.refService.getRef(), page: "review_start", httpService: this.httpService });
        this.reviewData = [
            {
                id: "fullName",
                title: "Full Name",
                value: this.data.fullName
                    ? this.data.fullName
                    : `${this.data.lastName} ${this.data.firstName}`,
            },
            {
                id: "dateOfBirth",
                title: "Date of Birth",
                value: this.data.dateOfBirth,
            },
            {
                id: "nationality",
                title: "Nationality",
                value: this.data.nationality,
            },
            {
                id: "idNumber",
                title: "ID Number",
                value: this.data.idNumber,
            },
            {
                id: "dateOfExpiration",
                title: "Date of Expiry",
                value: this.data.dateOfExpiration,
            },
        ];
    }

    proceed() {
        trackJourney({ ref: this.refService.getRef(), page: "review_success", httpService: this.httpService });        
        this.router.navigate(["/sign"]);
    }

    cancel() {
        this.configService.setTryAgain(true);
        this.router.navigate(["/facetec"]);
    }
}
