import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConfigService } from "src/app/service/config.service";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import packageInfo from "../../../../../../../package.json";
import { Config, Error, ErrorType } from "../../../dto";

@Component({
    selector: "app-eroor",
    templateUrl: "./error.component.html",
    styleUrls: ["./error.component.scss"],
})
export class ErrorComponent implements OnInit, OnDestroy {
    public version = packageInfo.version;

    config: Config;
    errors: Error[];
    errorCount = 0;
    lastError: Error;
    receivedErrors: Error[];

    @ViewChild("top") top: ElementRef;

    constructor(private configService: ConfigService, private router: Router) {}

    ngOnInit(): void {
        console.log("Facetec::ngOnInit");
        this.config = this.configService.getConfig() as Config;
        this.receivedErrors = this.configService.getReceivedErrors() as Error[];
        this.errorCount = this.configService.getErrorCount();
        this.lastError = this.configService.getLastError() as Error;

        if (!this.lastError && this.receivedErrors && this.receivedErrors.length > 0) {
            this.lastError = this.receivedErrors[this.receivedErrors.length - 1];
        }

        if (this.receivedErrors) {
            this.errors = this.receivedErrors;
        }
    }
    tryAgain() {
        this.configService.setTryAgain(true);
        this.router.navigate(["/facetec"]);
    }
    skip() {
        this.router.navigate(["/skip"]);
    }

    ngOnDestroy(): void {}

    get lastErrorDescription(): string {
        switch (this.lastError.errorType) {
            case ErrorType.FaceDidNotMatchId: {
                return "The face captured did not match the face on the ID document.";
            }
            case ErrorType.FaceLivenessFailed: {
                return "The face captured did not appear to be a live face.";
            }
            case ErrorType.IdScanFailed: {
                return "The ID scan failed. Please ensure you hold the camera steady and their is sufficient light to read your ID.";
            }
            case ErrorType.IdTypeNotAllowed: {
                return (
                    "The ID type " +
                    this.lastError.data.ocrTemplateName +
                    " used is not allowed. Please use a valid and allowed ID type."
                );
            }
            case ErrorType.IdTypeUnexpectedMedia: {
                return "The ID type did not appear to be a physical and / or legitimate document.";
            }
            case ErrorType.OcrTemplateMatchFailed: {
                return "The ID extraction failed as the ID type was not recognized.";
            }
        }

        return "An unknown error occurred";
    }
}
