import { Injectable } from "@angular/core";
import { Config as FacetecConfig } from "src/assets/Config";
import { SampleAppUtilities } from "src/assets/FaceTecAngularSampleApp/src/utilities/SampleAppUtilities";
import { ThemeHelpers } from "src/assets/FaceTecAngularSampleApp/src/utilities/ThemeHelpers";
import {
    FaceTecStrings,
    isNetworkResponseServerIsOffline,
    showAdditionalScreensServerIsDown,
} from "../constants";
import { AdditionalScreens } from "src/assets/FaceTecAngularSampleApp/src/utilities/AdditionalScreens";
import { DeveloperStatusMessages } from "src/assets/FaceTecAngularSampleApp/src/utilities/DeveloperStatusMessages";
import {
    FaceTecIDScanResult,
    FaceTecSessionResult,
} from "src/assets/core-sdk/FaceTecSDK.js/FaceTecPublicApi";
import { PhotoIDMatchService } from "./PhotoIDMatchService";
import { SessionService } from "./session.service";
import { DataService } from "./data.service";
import { ConfigService } from "./config.service";
import { HttpFacetecService } from "./http-facetec.service";
import { RefService } from "./ref.service";

@Injectable()
export class FaceTecSDKService {
    latestSessionResult: FaceTecSessionResult | null = null;
    latestIDScanResult: FaceTecIDScanResult | null = null;
    sessionTokenErrorHasBeenHandled = false;

    latestProcessor: PhotoIDMatchService | null = null;

    constructor(
        photoIdMatchService: PhotoIDMatchService,
        private dataService: DataService,
        private sessionService: SessionService,
        private configService: ConfigService,
        private refService: RefService,
        private httpService: HttpFacetecService,
    ) {
        this.latestProcessor = photoIdMatchService;
        FacetecConfig.requestNumber = 0;
    }

    initializeBrowserSDK({
        onInitializeSuccess,
        onInitializeFailed,
    }: {
        onInitializeSuccess: () => void;
        onInitializeFailed: () => void;
    }) {
        console.log("1");
        SampleAppUtilities.formatUIForDevice();

        console.log("2");
        // Set a the directory path for other FaceTec Browser SDK Resources.
        FaceTecSDK.setResourceDirectory("/assets/core-sdk/FaceTecSDK.js/resources");

        console.log("3");
        // Set the directory path for required FaceTec Browser SDK images.
        FaceTecSDK.setImagesDirectory("/assets/core-sdk/FaceTec_images");

        console.log("4");
        // Set your FaceTec Device SDK Customizations.
        ThemeHelpers.setAppTheme(ThemeHelpers.getCurrentTheme());

        console.log("5");
        // Initialize FaceTec Browser SDK and configure the UI features.
        const instance = this;
        FacetecConfig.initializeFromAutogeneratedConfig(
            FaceTecSDK,
            (initializedSuccessfully: boolean) => {
                if (initializedSuccessfully) {
                    console.log("initialise success");
                    instance.onFaceTecSDKInitializationSuccess(onInitializeSuccess);
                } else {
                    console.log("initialise failed!!");
                    instance.onFaceTecSDKInitializationFailure(onInitializeFailed);
                }
            },
        );

        console.log("7");
        SampleAppUtilities.fadeInMainUIContainer();
    }

    onFaceTecSDKInitializationSuccess(onInitializeSuccess: () => void): void {
        console.log("onFaceTecSDKInitializationSuccess :: 1");
        SampleAppUtilities.enableControlButtons();

        console.log("onFaceTecSDKInitializationSuccess :: 2");
        // Set your FaceTec Device SDK Customizations.
        ThemeHelpers.setAppTheme(ThemeHelpers.getCurrentTheme());

        console.log("onFaceTecSDKInitializationSuccess :: 3");
        // Set the sound files that are to be used for Vocal Guidance.
        SampleAppUtilities.setVocalGuidanceSoundFiles();

        console.log("onFaceTecSDKInitializationSuccess :: 4");
        FaceTecSDK.configureLocalization(FaceTecStrings);

        console.log("onFaceTecSDKInitializationSuccess :: 5");
        // Set the strings to be used for group names, field names, and placeholder texts for the FaceTec ID Scan User OCR Confirmation Screen.
        SampleAppUtilities.setOCRLocalization();

        console.log("onFaceTecSDKInitializationSuccess :: 6");

        SampleAppUtilities.displayStatus(
            FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus()),
        );

        console.log("onFaceTecSDKInitializationSuccess :: 7");
        // AdditionalScreens.setServerUpgradeStyling(
        //     document.getElementById("controls")!,
        //     this.exitAdditionalScreen.bind(this),
        // );

        console.log("onFaceTecSDKInitializationSuccess :: 8");
        DeveloperStatusMessages.logInitializeResult();

        onInitializeSuccess();
    }

    onFaceTecSDKInitializationFailure(onInitializeFailed: () => void): void {
        DeveloperStatusMessages.logInitializeResult();
        onInitializeFailed();
    }

    exitAdditionalScreen(): void {
        AdditionalScreens.exitAdditionalScreen(SampleAppUtilities.showMainUI);
    }

    // Perform a 3D Liveness Check, then an ID Scan, then Match the 3D FaceMap to the ID Scan.
    onPhotoIDMatchPressed(): void {
        this.initializeResultObjects();
        SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

        const lei = `_zain_eshop_${this.refService.getRef()}_${SampleAppUtilities.generateUUId()}`;

        this.httpService
            .sendExternalDBRef(
                this.refService.getRef(),
                lei,
                this.configService.getConfig().transactionId,
            )
            .subscribe(
                (_ret) => {},
                (_err) => {},
            );
            
        const instance = this;
        // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.  On Success, ID Scanning will start automatically.
        this.getSessionToken((sessionToken?: string): void => {
            instance.configService.setEnrollmentIdentifier(lei);
            instance.sessionService.setSession(sessionToken);
            instance.dataService.setData({
                ...instance.dataService.getData(),
                sessionId: sessionToken,
            });

            this.latestProcessor.start();
        });
    }

    // Clear previous session results;
    initializeResultObjects(): void {
        this.latestSessionResult = null;
        this.latestIDScanResult = null;
    }

    getSessionToken(sessionTokenCallback: (sessionToken: string) => void): void {
        this.sessionTokenErrorHasBeenHandled = false;

        try {
            var XHR = new XMLHttpRequest();
            FacetecConfig.requestNumber += 1;
            XHR.open(
                "GET",
                FacetecConfig.BaseURL + "/session-token?requestId=" + FacetecConfig.requestNumber,
            );
            XHR.setRequestHeader("X-Device-Key", FacetecConfig.DeviceKeyIdentifier);
            XHR.setRequestHeader("X-User-Agent", FaceTecSDK.createFaceTecAPIUserAgentString(""));
            XHR.setRequestHeader("Authorization", "Bearer " + this.configService.getConfig().token);
            XHR.setRequestHeader("Facetec-Tag", this.configService.getEnrollmentIdentifier());

            const instance = this;
            XHR.onreadystatechange = function (): void {
                if (this.readyState === XMLHttpRequest.DONE) {
                    var sessionToken = "";

                    try {
                        // Attempt to get the sessionToken from the response object.
                        sessionToken = JSON.parse(this.responseText).sessionToken;

                        // Something went wrong in parsing the response. Return an error.
                        if (typeof sessionToken !== "string") {
                            instance.onSessionTokenError(XHR.status);
                            return;
                        }
                    } catch {
                        // Something went wrong in parsing the response. Return an error.
                        XHR.abort();
                        instance.onSessionTokenError(XHR.status);
                        return;
                    }

                    SampleAppUtilities.hideLoadingSessionToken();
                    sessionTokenCallback(sessionToken);
                }
            };

            // Wait 3s, if the request is not completed yet, show the session token loading screen
            window.setTimeout(() => {
                if (XHR.readyState !== XMLHttpRequest.DONE) {
                    if (instance.sessionTokenErrorHasBeenHandled === false) {
                        SampleAppUtilities.showLoadingSessionToken();
                    }
                }
            }, 3000);

            XHR.onerror = function (): void {
                XHR.abort();
                instance.onSessionTokenError(XHR.status);
            };

            XHR.send();
        } catch (e) {
            this.onSessionTokenError(undefined);
        }
    }

    onSessionTokenError(xhrStatus: number | undefined): void {
        if (this.sessionTokenErrorHasBeenHandled === false) {
            this.sessionTokenErrorHasBeenHandled = true;

            if (xhrStatus !== undefined && isNetworkResponseServerIsOffline(xhrStatus)) {
                showAdditionalScreensServerIsDown();
            } else {
                this.onServerSessionTokenError();
            }
        }
    }

    onServerSessionTokenError(): void {
        SampleAppUtilities.handleErrorGettingServerSessionToken();
    }
}
