import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { BrowserAnimationsModule, provideAnimations } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from "ngx-spinner";
import { WebcamModule } from 'ngx-webcam';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { FaceTecComponent } from './modules/facetec/pages/facetec/facetec/facetec.component';
import type { FaceTecSDK as FaceTecSDKType } from "../assets/core-sdk/FaceTecSDK.js/FaceTecSDK";
import { SubmitComponent } from './modules/facetec/pages/facetec/submit/submit.component';
import { SignatureComponent } from './modules/facetec/pages/facetec/signature/signature.component';
import { ToastrModule, provideToastr } from 'ngx-toastr';
import { LandingComponent } from './modules/facetec/pages/facetec/landing/landing.component';
import { SkipCaptureComponent } from './modules/facetec/pages/facetec/skip-capture/skip-capture.component';
import { TakePhotoComponent } from './modules/facetec/pages/facetec/takephoto/takephoto.component';
import { ReviewComponent } from "./modules/facetec/pages/facetec/review/review.component";
import { FaceTecSDKService } from "./service/FaceTecSDKService";
import { ConfigService } from "./service/config.service";
import { RefService } from "./service/ref.service";
import { SessionService } from "./service/session.service";
import { PhotoIDMatchService } from "./service/PhotoIDMatchService";
import { EventsService } from "./service/events.service";
import { ErrorComponent } from "./modules/facetec/pages/facetec/error/error.component";
import { MRZDecoderService } from "./service/mrz.decode.server";

const routes: Routes = [
	// { path: 'home', component: HomeComponent },
	// { path: 'report', loadChildren: () => import('./modules/reporting/reporting.module').then(m => m.ReportingModule) },
	// { path: 'release-management', loadChildren: () => import('./modules/release-management/release-management.module').then(m => m.ReleaseManagementModule) },
	{ path: 'start', component: LandingComponent },
	{ path: 'facetec', loadChildren: () => import('./modules/facetec/facetec.module').then(m => m.FaceTecModule) },
	{ path: 'sign', component: SignatureComponent },
	{ path: 'submit', component: SubmitComponent },
	{ path: 'skip', component: SkipCaptureComponent },
	{ path: 'takephoto', component: TakePhotoComponent },
	{ path: 'review', component: ReviewComponent },
	{ path: 'error', component: ErrorComponent },
	// { path: 'ekyc', loadChildren: () => import('./modules/ekyc/ekyc.module').then(m => m.EkycModule) },
	// { path: '**', redirectTo: '/kiosk/list', pathMatch: 'full' },
	{ path: '**', component: LandingComponent },
	// { path: '**', component: FaceTecComponent },
];

@NgModule({
	declarations: [
		AppComponent,
  		MainPageComponent,
		FaceTecComponent,
		TakePhotoComponent,
		SubmitComponent,
		SignatureComponent,
		LandingComponent,
		SkipCaptureComponent,
		ReviewComponent,
		ErrorComponent
	],
	imports: [
		BrowserModule,
		NgbModule,
		WebcamModule,
		ZXingScannerModule,
		FontAwesomeModule,
		FormsModule,
		HttpClientModule,
		RouterModule.forRoot(routes, { useHash: false }),
		BrowserAnimationsModule,
		NgxSpinnerModule,
		ToastrModule.forRoot(),
	],
	exports: [RouterModule,NgxSpinnerModule],
	bootstrap: [AppComponent],
	providers: [
		FaceTecSDKService,
		ConfigService,
		RefService,
		SessionService,
		PhotoIDMatchService,
		EventsService,
		MRZDecoderService,
		provideAnimations(), // required animations providers
    	provideToastr(), // Toastr providers
	],
})
export class AppModule { }

declare global {
	const FaceTecSDK: typeof FaceTecSDKType;
}