import { Injectable } from "@angular/core";
import { Config, Error } from "../modules/facetec/dto";

@Injectable({
    providedIn: "root",
})
export class ConfigService {
    config: Config | null = null;
    tryAgain: boolean = false;
    receivedErrors: Error[] = [];
    lastError: Error | null = null;
    errorCount: number = 0;
    enrollmentIdentifier: string | null = null;

    setErrorCount(errorCount: number) {
        this.errorCount = errorCount;
    }

    getErrorCount() {
        return this.errorCount;
    }

    getEnrollmentIdentifier() {
        return this.enrollmentIdentifier;
    }

    setEnrollmentIdentifier(enrollmentIdentifier: string) {
        this.enrollmentIdentifier = enrollmentIdentifier;
    }

    getLastError() {
        return this.lastError;
    }

    setLastError(lastError: Error) {
        this.lastError = lastError;
    }

    setConfig(config: Config) {
        this.config = config;
    }

    getConfig(): Config | null {
        return this.config;
    }

    getTryAgain() {
        return this.tryAgain;
    }

    setTryAgain(tryAgain: boolean) {
        this.tryAgain = tryAgain;
    }

    getReceivedErrors() {
        return this.receivedErrors;
    }

    setRetrievedErrors(receivedErrors: Error[]) {
        this.receivedErrors = receivedErrors;
    }
}
