import { Config, Data, ImageTypeEnum } from "../modules/facetec/dto";
import { ConfigService } from "../service/config.service";
import { DataService } from "../service/data.service";
import { HttpFacetecService } from "../service/http-facetec.service";
import { MRZDecoderService, MRZType } from "../service/mrz.decode.server";

const setIdGroupName = (config: Config, data: Data) => {
    var idGroupName = findIdGroupName(config, data.barcodeTemplateType, data.barcodeTemplateName);
    if (!idGroupName) {
        idGroupName = findIdGroupName(config, data.ocrTemplateType, data.ocrTemplateName);
    }

    data.idGroupName = idGroupName;
};

const findIdGroupName = (config: Config, templateType: string, templateName: string): string => {
    if (!config.allowedIdTypes) {
        return undefined;
    }

    if (!templateType || !templateName) {
        return undefined;
    }

    var idGroupName: string;
    // try match on template name first
    config.allowedIdTypes.forEach((allowedIdTypeGroup) => {
        if (allowedIdTypeGroup.templateNames) {
            if (allowedIdTypeGroup.templateNames.filter((item) => item === templateName)[0]) {
                idGroupName = allowedIdTypeGroup.groupName;
            }
        }
    });

    if (idGroupName) {
        return idGroupName;
    }

    //if no match, then attempt to match on templateType
    config.allowedIdTypes.forEach((allowedIdTypeGroup) => {
        if (allowedIdTypeGroup.templateTypes) {
            if (allowedIdTypeGroup.templateTypes.filter((item) => item === templateType)[0]) {
                idGroupName = allowedIdTypeGroup.groupName;
            }
        }
    });

    return idGroupName;
};

const extractDate = (value: string, format: string) => {
    if (!format) {
        return value;
    }

    if (format === "dd MMM/MMM yyyy") {
        let indexSlash = value.indexOf("/");
        return value.slice(0, indexSlash) + value.slice(indexSlash + 4);
    }

    return value;
};

const processBarcodeData = (data: Data) => {
    if (!data.barcodeData) {
        return;
    }

    var userInfo = data.barcodeData.scannedValues.groups[0];
    if (userInfo && userInfo.fields) {
        if (!data.firstName) {
            data.firstName = userInfo.fields.filter(
                (field) => field.fieldKey === "firstName",
            )[0]?.value;
        }
        if (!data.lastName) {
            data.lastName = userInfo.fields.filter(
                (field) => field.fieldKey === "lastName",
            )[0]?.value;
        }
        if (!data.fullName) {
            data.fullName = userInfo.fields.filter(
                (field) => field.fieldKey === "fullName",
            )[0]?.value;
        }
        if (!data.nationality) {
            data.nationality = userInfo.fields.filter(
                (field) => field.fieldKey === "nationality",
            )[0]?.value;
            data.nationalityNonMRZValue = userInfo.fields.filter(
                (field) => field.fieldKey === "nationality",
            )[0]?.nonMRZValue;
        }
        if (!data.countryCode) {
            data.countryCode = userInfo.fields.filter(
                (field) => field.fieldKey === "countryCode",
            )[0]?.value;
            data.countryCodeNonMRZValue = userInfo.fields.filter(
                (field) => field.fieldKey === "countryCode",
            )[0]?.nonMRZValue;
        }
        if (!data.dateOfBirth) {
            var value = userInfo.fields.filter((field) => field.fieldKey === "dateOfBirth")[0]
                ?.value;
            var format = userInfo.fields.filter((field) => field.fieldKey === "dateOfBirth")[0]
                ?.uiFieldType;
            data.dateOfBirth = extractDate(value, format);
        }
        if (!data.placeOfBirth) {
            data.placeOfBirth = userInfo.fields.filter(
                (field) => field.fieldKey === "placeOfBirth",
            )[0]?.value;
        }
    }

    var idInfo = data.barcodeData.scannedValues.groups[1];
    if (idInfo && idInfo.fields) {
        if (!data.idNumber) {
            data.idNumber = idInfo.fields.filter(
                (field) => field.fieldKey === "idNumber",
            )[0]?.value;
        }
        if (!data.idBarcode) {
            data.idBarcode = idInfo.fields.filter(
                (field) => field.fieldKey === "barcode",
            )[0]?.value;
        }
        if (!data.dateOfIssue) {
            var value = idInfo.fields.filter((field) => field.fieldKey === "dateOfIssue")[0]?.value;
            var format = idInfo.fields.filter((field) => field.fieldKey === "dateOfIssue")[0]
                ?.uiFieldType;
            data.dateOfIssue = extractDate(value, format);
        }
        if (!data.dateOfExpiration) {
            var value = idInfo.fields.filter((field) => field.fieldKey === "dateOfExpiration")[0]
                ?.value;
            var format = idInfo.fields.filter((field) => field.fieldKey === "dateOfExpiration")[0]
                ?.uiFieldType;
            data.dateOfExpiration = extractDate(value, format);
        }
        if (!data.countryCode) {
            data.countryCode = idInfo.fields.filter(
                (field) => field.fieldKey === "countryCode",
            )[0]?.value;
        }
    }

    var secondaryUserInfo = data.barcodeData.scannedValues.groups[2];
    if (secondaryUserInfo && idInfo.fields) {
        if (!data.sex) {
            data.sex = secondaryUserInfo.fields.filter(
                (field) => field.fieldKey === "sex",
            )[0]?.value;
        }
    }

    data.barcodeTemplateName = data.barcodeData.templateInfo?.templateName;
    data.barcodeTemplateType = data.barcodeData.templateInfo?.templateType;
};

const processOcrData = (data: Data) => {
    if (!data.ocrData) {
        return;
    }

    var userInfo = data.ocrData.ocrResults.scanned.groups[0];
    if (userInfo && userInfo.fields) {
        if (!data.firstName) {
            data.firstName = userInfo.fields.filter(
                (field) => field.fieldKey === "firstName",
            )[0]?.value;
        }
        if (!data.lastName) {
            data.lastName = userInfo.fields.filter(
                (field) => field.fieldKey === "lastName",
            )[0]?.value;
        }
        if (!data.fullName) {
            data.fullName = userInfo.fields.filter(
                (field) => field.fieldKey === "fullName",
            )[0]?.value;
        }
        if (!data.nationality) {
            data.nationality = userInfo.fields.filter(
                (field) => field.fieldKey === "nationality",
            )[0]?.value;
        }
        if (!data.countryCode) {
            data.countryCode = userInfo.fields.filter(
                (field) => field.fieldKey === "countryCode",
            )[0]?.value;
        }
        if (!data.dateOfBirth) {
            var value = userInfo.fields.filter((field) => field.fieldKey === "dateOfBirth")[0]
                ?.value;
            var format = userInfo.fields.filter((field) => field.fieldKey === "dateOfBirth")[0]
                ?.uiFieldType;
            data.dateOfBirth = extractDate(value, format);
        }
        if (!data.placeOfBirth) {
            data.placeOfBirth = userInfo.fields.filter(
                (field) => field.fieldKey === "placeOfBirth",
            )[0]?.value;
        }
    }

    var idInfo = data.ocrData.ocrResults.scanned.groups[1];
    if (idInfo && idInfo.fields) {
        if (!data.idNumber) {
            data.idNumber = idInfo.fields.filter(
                (field) => field.fieldKey === "idNumber",
            )[0]?.value;
        }
        if (!data.dateOfIssue) {
            var value = idInfo.fields.filter((field) => field.fieldKey === "dateOfIssue")[0]?.value;
            var format = idInfo.fields.filter((field) => field.fieldKey === "dateOfIssue")[0]
                ?.uiFieldType;
            data.dateOfIssue = extractDate(value, format);
        }
        if (!data.dateOfExpiration) {
            var value = idInfo.fields.filter((field) => field.fieldKey === "dateOfExpiration")[0]
                ?.value;
            var format = idInfo.fields.filter((field) => field.fieldKey === "dateOfExpiration")[0]
                ?.uiFieldType;
            data.dateOfExpiration = extractDate(value, format);
        }
        if (!data.countryCode) {
            data.countryCode = idInfo.fields.filter(
                (field) => field.fieldKey === "countryCode",
            )[0]?.value;
        }
    }

    var addressInfo = data.ocrData.ocrResults.scanned.groups[2];
    if (addressInfo && addressInfo.fields) {
        data.address = addressInfo.fields.filter((field) => field.fieldKey === "address")[0]?.value;
    }

    var secondaryUserInfo = data.ocrData.ocrResults.scanned.groups[3];
    if (secondaryUserInfo && secondaryUserInfo.fields) {
        if (!data.sex) {
            data.sex = secondaryUserInfo.fields.filter(
                (field) => field.fieldKey === "sex",
            )[0]?.value;
        }
    }

    data.ocrTemplateName = data.ocrData.ocrResults?.templateName;
    data.ocrTemplateType = data.ocrData.ocrResults?.templateType;
};

const processUserConfirmedValues = (data: Data) => {
    if (!data.userConfirmedValues) {
        return;
    }

    var userInfo = data.userConfirmedValues.groups[0];
    if (userInfo && userInfo.fields) {
        data.firstName = userInfo.fields.filter(
            (field) => field.fieldKey === "firstName",
        )[0]?.value;
        data.lastName = userInfo.fields.filter((field) => field.fieldKey === "lastName")[0]?.value;
        data.fullName = userInfo.fields.filter((field) => field.fieldKey === "fullName")[0]?.value;
        data.nationality = userInfo.fields.filter(
            (field) => field.fieldKey === "nationality",
        )[0]?.value;
        data.countryCode = userInfo.fields.filter(
            (field) => field.fieldKey === "countryCode",
        )[0]?.value;
        data.dateOfBirth = userInfo.fields.filter(
            (field) => field.fieldKey === "dateOfBirth",
        )[0]?.value;
        data.placeOfBirth = userInfo.fields.filter(
            (field) => field.fieldKey === "placeOfBirth",
        )[0]?.value;
    }

    var idInfo = data.userConfirmedValues.groups[1];
    if (idInfo && idInfo.fields) {
        data.idNumber = idInfo.fields.filter((field) => field.fieldKey === "idNumber")[0]?.value;
        data.dateOfIssue = idInfo.fields.filter(
            (field) => field.fieldKey === "dateOfIssue",
        )[0]?.value;
        data.dateOfExpiration = idInfo.fields.filter(
            (field) => field.fieldKey === "dateOfExpiration",
        )[0]?.value;
    }

    var secondaryUserInfo = data.userConfirmedValues.groups[2];
    if (secondaryUserInfo && idInfo.fields) {
        data.sex = secondaryUserInfo.fields.filter((field) => field.fieldKey === "sex")[0]?.value;
    }
};

export const processMrzData = (data: Data, mrzService: MRZDecoderService) => {
    try {
        const idInfo = data.barcodeData.scannedValues.groups[1];
        const mrzLine1Field = idInfo.fields.find((field) => {
            field.fieldKey = "mrzLine1";
        });

        if (!mrzLine1Field) {
            return;
        }

        const mrzLine2Field = idInfo.fields.find((field) => {
            field.fieldKey = "mrzLine2";
        });

        if (!mrzLine2Field) {
            return;
        }

        const mrzLine3Field = idInfo.fields.find((field) => {
            field.fieldKey = "mrzLine3";
        });

        if (!mrzLine3Field) {
            return;
        }

        const mrz = [mrzLine1Field.value, mrzLine2Field.value, mrzLine3Field.value];

        const decoded = mrzService.decode(mrz);

        if (decoded.type === MRZType.SAUDI_ID) {
            data.countryCode = decoded.issuingCountry;
            data.dateOfExpiration = decoded.expiryDate;
            data.dateOfBirth = decoded.birthDate;
            data.placeOfBirth = decoded.nationality;
            data.idNumber = decoded.documentNumber;
            data.firstName = decoded.givenNames;
            data.lastName = decoded.surname;
            data.fullName = `${decoded.givenNames} ${decoded.surname}`;
        }
    } catch (e) {
        // do nothing
    }
};

export const processIdResult = ({
    result,
    dataService,
    configService,
    mrzService,
    handleErrors,
    proceed,
}: {
    result: any;
    dataService: DataService;
    configService: ConfigService;
    mrzService: MRZDecoderService;
    handleErrors: (res) => boolean;
    proceed: () => void;
}) => {
    const data = dataService.getData();
    const config = configService.getConfig();

    if (result.didMatchIDScanToOCRTemplate === true) {
        if (result.userConfirmedValues) {
            const userConfirmedValues = JSON.parse(result.userConfirmedValues);
            data.userConfirmedValues = userConfirmedValues;
            processUserConfirmedValues(data);
        }
        if (result.documentData) {
            const documentData = JSON.parse(result.documentData);
            data.barcodeData = documentData;
            processBarcodeData(data);
            processMrzData(data, mrzService);
        }
        if (result.ocrResults) {
            const ocrResults = JSON.parse(result.ocrResults);
            data.ocrData = ocrResults;
            processOcrData(data);
        }
    }

    var idPhotoFront = data.imageList.filter((item) => item.type === ImageTypeEnum.IdPhotoFront)[0];
    if (result.photoIDFrontCrop) {
        if (idPhotoFront) {
            idPhotoFront.image = result.photoIDFrontCrop;
        } else {
            data.imageList.push({
                image: result.photoIDFrontCrop,
                type: ImageTypeEnum.IdPhotoFront,
            });
        }
    }

    var idPhotoBack = data.imageList.filter((item) => item.type === ImageTypeEnum.IdPhotoBack)[0];
    if (result.photoIDBackCrop) {
        if (idPhotoBack) {
            idPhotoBack.image = result.photoIDBackCrop;
        } else {
            data.imageList.push({
                image: result.photoIDBackCrop,
                type: ImageTypeEnum.IdPhotoBack,
            });
        }
    }

    var idPhotoFace = data.imageList.filter((item) => item.type === ImageTypeEnum.IdPhotoFace)[0];
    if (result.photoIDFaceCrop) {
        if (idPhotoFace) {
            idPhotoFace.image = result.photoIDFaceCrop;
        } else {
            data.imageList.push({
                image: result.photoIDFaceCrop,
                type: ImageTypeEnum.IdPhotoFace,
            });
        }
    }

    var idSignature = data.imageList.filter((item) => item.type === ImageTypeEnum.IdSignature)[0];
    if (result.photoIDPrimarySignatureCrop) {
        if (idSignature) {
            idSignature.image = result.photoIDPrimarySignatureCrop;
        } else {
            data.imageList.push({
                image: result.photoIDPrimarySignatureCrop,
                type: ImageTypeEnum.IdSignature,
            });
        }
    }

    var tamperFront = data.imageList.filter(
        (item) => item.type === ImageTypeEnum.TamperingFrontEvidence,
    )[0];
    if (result.photoIDTamperingEvidenceFrontImage) {
        if (tamperFront) {
            tamperFront.image = result.photoIDTamperingEvidenceFrontImage;
        } else {
            data.imageList.push({
                image: result.photoIDTamperingEvidenceFrontImage,
                type: ImageTypeEnum.TamperingFrontEvidence,
            });
        }
    }

    var tamperBack = data.imageList.filter(
        (item) => item.type === ImageTypeEnum.TamperingBackEvidence,
    )[0];
    if (result.photoIDTamperingEvidenceBackImage) {
        if (tamperBack) {
            tamperBack.image = result.photoIDTamperingEvidenceBackImage;
        } else {
            data.imageList.push({
                image: result.photoIDTamperingEvidenceBackImage,
                type: ImageTypeEnum.TamperingBackEvidence,
            });
        }
    }

    data.idPhotoMatchLevel = result.matchLevel;
    data.ageEstimate = result.idScanAgeEstimateGroupV2EnumInt; //could also be result.idScanAgeV2GroupEnumInt (who the fuck knows)
    if (result.additionalSessionData) {
        data.browser = result.additionalSessionData.userAgent;
        data.deviceModel = result.additionalSessionData.deviceModel;
        data.deviceSDK = result.additionalSessionData.deviceSDKVersion;
        data.platform = result.additionalSessionData.platform;
        data.ipAddress = result.additionalSessionData.ipAddress;
    }

    setIdGroupName(config, data);

    dataService.setData(data);

    if (
        result.isCompletelyDone === true &&
        result.matchLevel >= configService.getConfig().minMatchLevel
    ) {
        if (result.axonMissingInformation) {
            handleErrors({ ...result });
        } else {
            proceed();
        }
    } else {
        console.log("Handling Error Match Failed:", result);
        handleErrors({ ...result, didCompleteIDScanWithoutMatching: true });
    }
};

export const processPortraitResult = (result: any, dataService: DataService) => {
    const data = dataService.getData();
    console.log(data);

    var portrait = data.imageList.filter((item) => item.type === ImageTypeEnum.Portrait)[0];
    if (portrait) {
        portrait.image = result;
        console.log("Data:", data);
        return;
    }
    data.imageList.push({
        image: result,
        type: ImageTypeEnum.Portrait,
    });

    dataService.setData(data);
    console.log("Data:", data);
};

export const trackJourney = ({
    ref,
    page,
    httpService,
}: {
    ref: string;
    page: string;
    httpService: HttpFacetecService;
}) => {
    httpService.trackJourney(ref, page).subscribe((_data) => {
        console.log(`${page} tracked`);
    });
};
